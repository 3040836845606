import { spinner } from './spinner.css.ts';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { clsx } from 'clsx';

export type SpinnerProps = {
  size?: number;
} & ComponentPropsWithoutRef<'div'>;

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  function SpinnerComponent({ className, size = 16 }, ref) {
    return (
      <div
        ref={ref}
        className={clsx(spinner, className)}
        style={{ width: size, height: size }}
      />
    );
  },
);
